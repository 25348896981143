<template>
  <div>
    <b-card no-body class="card-statistics">
      <b-card-header>
        <b-card-title>Statistics</b-card-title>
        <b-card-text class="mr-25 mb-0">
          Updated Now
        </b-card-text>
      </b-card-header>
      <b-card-body class="statistics-body">
        <b-row>
          <b-col
            v-for="item in statisticsItems"
            :key="item.icon"
            md="3"
            sm="6"
            class="mb-2 mb-md-0"
            :class="item.customClass"
          >
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar size="48" :variant="item.color">
                  <feather-icon size="24" :icon="item.icon" />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ item.title }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  {{ item.subtitle }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-row class="match-height">
      <b-col xl="2" md="4" sm="6">
        <statistic-card-vertical
          color="warning"
          icon="PeopleIcon"
          :statistic="statistikDataBody.nonaktif_berakhir"
          statistic-title="Jumlah Masa Keanggotaan Berakhir"
        />
      </b-col>
      <b-col xl="2" md="4" sm="6">
        <statistic-card-vertical
          color="danger"
          icon="PeopleIcon"
          :statistic="statistikDataBody.nonaktif_pengakhiran"
          statistic-title="Jumlah Keanggotaan Pengakhiran"
        />
      </b-col>
      <b-col xl="2" md="4" sm="6">
        <statistic-card-vertical
          color="primary"
          icon="PeopleIcon"
          :statistic="statistikDataBody.calon_advokat"
          statistic-title="Calon Advokat"
        />
      </b-col>
      <b-col xl="2" md="4" sm="6">
        <statistic-card-vertical
          color="success"
          icon="PeopleIcon"
          :statistic="statistikDataBody.anggota_belum_bas"
          statistic-title="Anggota Belum Unggah BAS"
        />
      </b-col>
      <b-col xl="2" md="4" sm="6">
        <statistic-card-vertical
          icon="PeopleIcon"
          :statistic="statistikDataBody.tidak_diketahui"
          statistic-title="Tidak Diketahui"
          color="info"
        />
      </b-col>
      <b-col xl="2" md="4" sm="6">
        <statistic-card-vertical
          hide-chart
          color="danger"
          icon="PeopleIcon"
          :statistic="statistikDataBody.advokat_non_anggota"
          statistic-title="Advokat Non Anggota"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6">
        <b-card title="Anggota Aktif per DPC">
          <b-form @submit.prevent>
            <b-row>
              <b-col cols="12">
                <b-form-group>
                  <label>DPC</label>
                  <v-select
                    v-if="optionsDpnDpc"
                    v-model="selectedDpnDpc"
                    :options="optionsDpnDpc"
                    label="nama"
                    :clearable="true"
                  />
                </b-form-group>
              </b-col>

              <!-- submit and reset -->
              <b-col cols="12">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  class="mr-1"
                  @click="download"
                >
                  Download
                </b-button>
              </b-col>
            </b-row>
          </b-form>
          <b-overlay :show="processingAnggotaAktifDpc" no-wrap>
            <template #overlay>
              <b-spinner variant="primary" label="Text Centered" />
            </template>
          </b-overlay>
        </b-card>
      </b-col>
      <b-col cols="6">
        <b-card title="Anggota Tidak Aktif per DPC">
          <b-form @submit.prevent>
            <b-row>
              <b-col cols="12">
                <b-form-group>
                  <label>DPC</label>
                  <v-select
                    v-if="optionsDpnDpc"
                    v-model="selectedDpnDpcAnggotaTidakAktif"
                    :options="optionsDpnDpc"
                    label="nama"
                    :clearable="true"
                  />
                </b-form-group>
              </b-col>

              <!-- submit and reset -->
              <b-col cols="12">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  class="mr-1"
                  @click="downloadTidakAktif"
                >
                  Download
                </b-button>
              </b-col>
            </b-row>
          </b-form>
          <b-overlay :show="processingAnggotaTidakAktifDpc" no-wrap>
            <template #overlay>
              <b-spinner variant="primary" label="Text Centered" />
            </template>
          </b-overlay>
        </b-card>
      </b-col>
      <b-col cols="6">
        <b-card title="Advokat Lain-lain per DPC">
          <b-form @submit.prevent>
            <b-row>
              <b-col cols="12">
                <b-form-group>
                  <label>DPC</label>
                  <v-select
                    v-if="optionsDpnDpc"
                    v-model="selectedDpnDpcAdvokatLainLain"
                    :options="optionsDpnDpc"
                    label="nama"
                    :clearable="true"
                  />
                </b-form-group>
              </b-col>

              <!-- submit and reset -->
              <b-col cols="12">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  class="mr-1"
                  @click="downloadAdvokatLainLain"
                >
                  Download
                </b-button>
              </b-col>
            </b-row>
          </b-form>
          <b-overlay :show="processingAdvokatLainLainDpc" no-wrap>
            <template #overlay>
              <b-spinner variant="primary" label="Text Centered" />
            </template>
          </b-overlay>
        </b-card>
      </b-col>
      <b-row />
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BFormGroup,
  // BFormInput,
  BForm,
  BButton,
  BOverlay,
  BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import { api, getCacheableApi, download } from '@/hap-sia/setup'
import { title } from '@/@core/utils/filter'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    StatisticCardVertical,
    BFormGroup,
    // BFormInput,
    BForm,
    BButton,
    vSelect,
    BOverlay,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      statistikDataBody: {
        total_anggota: 0,
        anggota_aktif: 0,
        nonaktif_berakhir: 0,
        nonaktif_pengakhiran: 0,
        calon_advokat: 0,
        anggota_belum_bas: 0,
        tidak_diketahui: 0,
        advokat_non_anggota: 0,
        gender_laki: 0,
        gender_perempuan: 0,
      },
      statisticsItems: [
        {
          icon: 'PeopleIcon',
          color: 'light-primary',
          title: '0',
          subtitle: 'Total Anggota',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'PeopleIcon',
          color: 'light-info',
          title: '0',
          subtitle: 'Total Anggota Aktif',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'PeopleIcon',
          color: 'light-success',
          title: '0',
          subtitle: 'Laki-laki',
          customClass: '',
        },
        {
          icon: 'PeopleIcon',
          color: 'light-danger',
          title: '0',
          subtitle: 'Perempuan',
          customClass: 'mb-2 mb-sm-0',
        },
      ],
      optionsDpnDpc: [],
      selectedDpnDpc: null,
      selectedDpnDpcAnggotaTidakAktif: null,
      selectedDpnDpcAdvokatLainLain: null,
      processingAnggotaAktifDpc: false,
      processingAnggotaTidakAktifDpc: false,
      processingAdvokatLainLainDpc: false,
    }
  },
  async mounted() {
    const result = await api().get('laporan/statistik')
    this.statistikDataBody.anggota_aktif = result.data[0].anggota_aktif
    this.statistikDataBody.nonaktif_berakhir =
      result.data[0].tidak_aktif_keanggotaan
    this.statistikDataBody.nonaktif_pengakhiran =
      result.data[0].tidak_aktif_pengakhiran
    this.statistikDataBody.calon_advokat = result.data[0].calon_advokat
    this.statistikDataBody.anggota_belum_bas = result.data[0].bas_belum_unggah
    this.statistikDataBody.tidak_diketahui = result.data[0].tidak_diketahui
    this.statistikDataBody.advokat_non_anggota =
      result.data[0].advokat_non_anggota
    this.statistikDataBody.total_anggota =
      Number(result.data[0].anggota_aktif) +
      Number(result.data[0].tidak_aktif_keanggotaan) +
      Number(result.data[0].tidak_aktif_pengakhiran) +
      Number(result.data[0].calon_advokat) +
      Number(result.data[0].bas_belum_unggah) +
      Number(result.data[0].tidak_diketahui) +
      Number(result.data[0].advokat_non_anggota)
    this.statistikDataBody.gender_laki = result.data[0].gender_laki
    this.statistikDataBody.gender_perempuan = result.data[0].gender_perempuan
    this.statisticsItems[0].title = this.statistikDataBody.total_anggota
    this.statisticsItems[1].title = this.statistikDataBody.anggota_aktif
    this.statisticsItems[2].title = this.statistikDataBody.gender_laki
    this.statisticsItems[3].title = this.statistikDataBody.gender_perempuan

    const cacheApi = await getCacheableApi()
    const resp = await cacheApi.get('master/struktur-organisasi')
    this.optionsDpnDpc.push({ id: -1, nama: 'Semua' })

    resp.data.forEach(element => {
      this.optionsDpnDpc.push({
        id: element.id,
        nama: element.nama,
      })
    })
  },
  methods: {
    download1() {
      // alert(this.selectedDpnDpc.id)
      console.log(this.selectedDpnDpc)
      this.processingAnggotaAktifDpc = true
    },
    async download() {
      try {
        this.processingAnggotaAktifDpc = true
        // console.log(row)
        // await new Promise(r => setTimeout(r, 2000))
        const result = await download().get(
          `laporan/list/anggota-aktif?dpn_dpc_id=${this.selectedDpnDpc.id}&export=xls`,
        )
        // console.log(result)
        // this.fileQR = result.data.url
        const url = window.URL.createObjectURL(new Blob([result.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'anggota-aktif.xlsx') // or any other extension
        document.body.appendChild(link)
        link.click()
      } catch (error) {
        alert(error)
      }
      this.processingAnggotaAktifDpc = false
    },
    async downloadTidakAktif() {
      try {
        this.processingAnggotaTidakAktifDpc = true
        const result = await download().get(
          `laporan/list/anggota-tidak-aktif?dpn_dpc_id=${this.selectedDpnDpcAnggotaTidakAktif.id}&export=xls`,
        )
        const url = window.URL.createObjectURL(new Blob([result.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'anggota-tidak-aktif.xlsx') // or any other extension
        document.body.appendChild(link)
        link.click()
      } catch (error) {
        alert(error)
      }
      this.processingAnggotaTidakAktifDpc = false
    },
    async downloadAdvokatLainLain() {
      try {
        this.processingAdvokatLainLainDpc = true
        const result = await download().get(
          `laporan/list/advokat-lainlain?dpn_dpc_id=${this.selectedDpnDpcAdvokatLainLain.id}&export=xls`,
        )
        const url = window.URL.createObjectURL(new Blob([result.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'advokat_lainlain.xlsx') // or any other extension
        document.body.appendChild(link)
        link.click()
      } catch (error) {
        alert(error)
      }
      this.processingAdvokatLainLainDpc = false
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style></style>
